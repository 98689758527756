.main_div{
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 5px 5px 0px 0px;
    background: #E9FAFF;
}
.title_div{
    background-color: #F2F2F2;
    padding: 10px;
    border-bottom: 1px solid #D9D9D9;
}
.body_div{
    /* height: 300px; */
    min-height: 300px;
    max-height: 580px;
}
.chat_icon{
    width: 22px;
}
.bot_person{
  width: 27px;
  height: 40px;
}
.msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    margin: 10px 10px;
  }
  
  .msger_header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    background: #eee;
    color: #666;
  }
  
  .msger_chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    position: relative;
  }
  .msger_chat::-webkit-scrollbar {
    width: 6px;
  }
  .msger_chat::-webkit-scrollbar-track {
    background: #ddd;
  }
  .msger_chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }
  .msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  .msg:last-of-type {
    margin: 0;
  }
  .msg_img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #fff;
    border-radius: 50%;
  }
  .msg_bubble {
    max-width: 450px;
    padding: 10px;
    border-radius: 15px;
    background: #fff;
    border: 1px solid #C4C4C4;
  }
  .msg_text {
    font-size: 14px;
  }
  .msg_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .msg_info_name {
    margin-right: 10px;
    color: #00A5D9;
    font-size: 10px;
  }
  .msg_info_name_right {
    margin-right: 10px;
    color: #fff;
    font-size: 10px;
  }
  .msg_info_time {
    font-size: 0.85em;
  }
  
  .left_msg .msg_bubble {
    border-bottom-left-radius: 0;
  }
  
  .right_msg {
    flex-direction: row-reverse;
  }
  .right_msg .msg_bubble {
    background: #00A5D9;
    color: #fff;
    border-bottom-right-radius: 0;
    border: 1px solid #037599;
  }
  
  .right_msg .msg_bubble_orange {
    background: #E98300;
    color: #fff;
    border-bottom-right-radius: 0;
    border: 1px solid #E98300;
  }
  .msg_bubble_orange {
    max-width: 450px;
    padding: 10px;
    border-radius: 15px;
    background: #fff;
    border: 1px solid #C4C4C4;
  }
  
  .right_msg .msg_img {
    margin: 0 0 0 10px;
  }
  .keyword_highlight{
    background-color:#FDBC15 ;
    color: black;
  }
  
  