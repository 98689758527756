.url-box {
    width: 347px;
}
.url-box-input input {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
}
.url-box-input label {
    height:46px;
}
.label-box {
  background: var(--light-grey-2);
  padding: 11px 24px;
  border: 1px solid #D9D9D9;
  border-right: 0px;
  text-align: center;
}
.get-url-box-out {
    min-height: 570px;
}
.get-url-box-in {
    /* min-height: 217px; */
}
.url-box-input {
    width: 100%;
}
.get-url-button {
    width: 100%;
    background: #FBFBFB;
    display: flex;
    justify-content: space-between;    
    align-items: center;
    padding: 0px 20px;
    border: 1px solid #D6E4EC;
    padding: 8px 16px 8px 24px;
    border-radius: 5px;
}
.get-url-button .icon {
    background: url(../../../assets/images/down_arrow.png) no-repeat center center;    
    width: 12px;
    height: 8px;
    cursor: pointer;
    background-size: contain;
}
.get-url-button .icon.show {
    background: url(../../../assets/images/up_arrow.png) no-repeat center center;   
    background-size: contain;
}
.border-radious-none {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}  

.border-radious {
    border-radius: 5px;
}  
.get-url-content {
    margin-top: -2px;
    display: none;
    background: #FBFBFB;
    border: 0.678715px solid #D6E4EC;
    padding: 16px 24px;
}
.get-url-content.show {
    display: block;
}
.get-url-content>.row>div {
    border: 1px solid #D9D9D9;
}
.get-url-content>.row>div {
    padding: 16px 8px;
}
.get-url-content>.row>div:last-child {
    padding: 16px 24px;
}
.instruction-list {
    list-style: none;
    padding-left: 0;
}
.instruction-list li{
    padding: 10px 5px;
    text-align: left;
    font-size: 12px;
    color: var(--dune);
}
.help-box {
    border-radius: 4px;
}
.help-box>div {
    background: #fff;
}
.help {
    text-align: left;
    padding-left: 5px;
    color: var(--dune);
}
#loading-box {
    display: none;
}
#loading-box.show {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(196, 196, 196, 0.7);
    z-index: 9;
    justify-content: center;
    align-items: center;
}
#loading-box>p {
    width: 280px;
    background: #FFFFFF;
    padding: 10px 5px;
    text-align: center;
    border: 1px solid #000000;
    box-sizing: border-box;
    margin-top: -230px;
}

.list-circle {
    width: 13px;
    height: 12px;
    border-radius: 50%;
    text-align: center;
    font-size: 7px;
    border: 1px solid #000;
    margin-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pastedurl-box {
    width: calc(100% - 137px) !important;
}
.pastedurl-box > input {
    width: 100% !important;
    padding-right: 38px;
}
@media screen and (max-width: 991.98px) {
    .url-box  {
        width: auto;
    }
}
