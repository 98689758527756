.ng_word_header_height{
    height: 77px;
}
.ng_word_body_height{
    height: 122px;
}
.is_custom_bg {
    background-color: #E9FAFF;
    border: 1px solid #00A5D9;
}
.ngWordScroll{
    height: 122px;
    overflow-y: auto;
}
.custom_habitchip_pd {
    background: #FBFBFB;
    display: inline-flex;
}
.custom_habitchip_pd>span {
    padding: 2px;
}
.custom_bg_chit {
    border-radius: 4px;
    padding: 2px 4px;
}
.custom_habitchip_pd,
.custom_bg_chit {
    margin-right: 4px;
}
.double-tb-inner tr:first-child td, 
.double-tb-inner tr:first-child th {
    border-top: 0;
}
.double-tb-inner tr td:first-child, 
.double-tb-inner tr th:first-child {
    border-left: 0;
}
.double-tb-inner tr td:last-child, 
.double-tb-inner tr th:last-child {
    border-right: 0;
}
.double-tb-inner tr:last-child td, 
.double-tb-inner tr:last-child th {
    border-bottom: 0;
}

@media screen and (min-width: 1200px) {

    .custom_habitchip_pd,
    .custom_bg_chit {
        margin-right: 8px;
    }
}