.cmn-btn {
  width: 90px;
  background-color: var(--cerulean);
  padding: 5px;
  color: var(--pure-white);
  text-transform: uppercase;
  border-radius: 3px;
}
.btn-white {
  background-color: var(--pure-white);
  color: var(--cerulean);
  border: 1px solid #979797;
}
.no_data {
  height: 104px;
}
.no_data > td {
  vertical-align: inherit !important;
  text-align: center !important;
}
.trainee_table tr td:first-child {
  font-weight: bold;
  text-align: left;
  padding-left: 22px;
  background: #f2f2f2;
}

.trainee_table tr:nth-child(2) th:first-child,
.trainee_table tr td:first-child {
  border-left-width: 2px;
}
.trainee_table {
  max-height: 450px;
  overflow-y: auto;
}
.trainee_table::-webkit-scrollbar {
  width: 16px; /* width of the entire scrollbar */
  border-bottom-right-radius: 10px;
}
.trainee_table::-webkit-scrollbar-track {
  background: #E6E6E6; /* color of the tracking area */
  border-bottom-right-radius: 10px;
}
.trainee_table::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}
.trainee_table > table {
  border-collapse: separate;
  border-spacing: 0;
}
.trainee_table thead {
  position: sticky;
  top: 0px;
  z-index: 10;
}
@media screen and (max-width: 1200px) {
  .trainee_table tr td:first-child {
    padding-left: 6px;
  }
}
