.general_textbox {
    width: 100%;
    position: relative;
}
.input {
    width: 100%;
    padding: 16px 22px;
    /* outline: none; */
    border: 1px solid var(--granite);
    border-radius: 6px;
    background-color: inherit;
     /* margin-left: 0px!important; */
}
.input::placeholder {
    font-size: 18px;
    /* padding-right: 8px;
    padding-left: 8px; */
    color: var(--light-grey-6);
}
.input:focus {
    border: 1px solid var(--cerulean);
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    background-color: var(--pure-white);
}
.icon {
    display: none;
    /* margin-left: -30px; */
    max-width: 20px;
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
}