.roleplay_count_and_duration_tab {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.bar_height {
  height: 660px;
  overflow-y: auto;
}
.bar_height::-webkit-scrollbar-track {
  background-color: var(--light-grey-0);
}
.bar_height::-webkit-scrollbar {
  width: 12px;
  background-color: var(--light-grey-0);
}
.bar_height::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--cerulean);
}

@media screen and (max-width: 1300px) and (min-width: 999px) {
  .AS_code_dropdown {
    max-width: 100%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 999px) {
  .AS_code_dropdown {
  }
}
