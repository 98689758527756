.select_box {
    width: 100%;
    padding: 8px 16px 8px 24px;
    position: relative;
    cursor: pointer;
    background: #FBFBFB;
    border: 0.678715px solid #D6E4EC;
    box-sizing: border-box;
    border-radius: 5px;
}

.select_box_radius {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.box_body{
    position: absolute;
    width: 501px;
    background-color: white;
    top: 39px;
    z-index: 1;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.1);
}
.p_left{
    left: 0px;
    z-index: 3;
}
.p_right{
    right: 0px;
}
.from_section{
    border: 2px solid #00A5D9;
    border-bottom-left-radius: 10px;
}
.to_section{
    border: 2px solid #f8fbfc;
    border-bottom-right-radius: 10px;
}
.from{
    background-color: #00A5D9;
    border-top-left-radius: 10px;
}
.select_box .showbox {
    transition: all  ease-in-out;
}

.select_box .hidebox {
    visibility: hidden;
}
.active_item{
    background-color: #00A5D9;
    color: white;
    border-radius: 10px;
}


.calendar {
    width: 12px;
}
.bg_gray {
    color: var(--light-grey-6);
    border-radius: 10px;
    margin-bottom: 2px;
}