.chip_plus{
    padding: 8px 15px;
    border-radius: 8px;
    margin-right: 10px;
    border: 1px solid #00A5D9;
    color: #00A5D9;
    cursor: pointer;
    display: inline-block;
}
.chip_plus >span>img{
    width: 12px;
    margin-left: 10px;
}