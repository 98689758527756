.mouth_habit{
    padding: 3px 0px;
    border-radius: 3px;
    margin-right: 3px;
    border: 0.5px solid #00A5D9;
    display: inline-block;
}
.mouth_habit_name{
    background-color: #E9FAFF;
    padding: 3px 20px;
    border-right: 0.5px solid #00A5D9;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}