.confirm_dialog_width{
    width: 30% !important;
    margin: 0 auto !important;
    padding: 15px !important;
}
.MuiDialogActions-root {
    display: inline !important;
}
.cancel{
    cursor: pointer;
    left: '95%';
    top: '-9%';
}