.ai_header_section{
    align-items: center;
    margin-bottom: 40px;
}
.recording_state{
    border: 1px solid #E98300;
    padding: 5px;
    color: #E98300;
    border-radius: 4px;
}
.recording_icon{
    width: 20px;
}
.ai_btn_box button:first-child { 
    padding-left: 0;
    padding-right: 0;
}
@media screen and (max-width: 1199.98px) {
    .ai_btn_box button:first-child {
        width: auto !important;
        display: block;
        padding-left: 24px;
        padding-right: 24px;
    }
}