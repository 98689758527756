#select-scenario {
  padding-bottom: 37px;
}
.star_new_role_step {
  padding: 5px 16px 6px;
}
.nav {
  /* margin-bottom: 32px; */
}
.sub_nav:nth-of-type(1) {
  padding-left: 24px;
}
.sub_nav {
  display: flex;
}
.sub_nav p {
  font-size: 16px;
  text-align: center;
}
.sub_nav:nth-child(2) p {
  margin-bottom: 0;
}
.active {
  background-color: var(--cerulean) !important;
  color: #fff;
}
.step_label {
  position: absolute;
  top: 50px;
}

.steps {
  display: flex;
  flex-direction: column;
}
.sub_step {
  display: flex;
  align-items: center;
}
p.label:first-child {
  width: 100px;
}
p.label:nth-child(2) {
  width: 200px;
  text-align: center;
}
p.label:last-child {
  width: 110px;
  text-align: center;
}
.loading_box {
  display: none;
}
.show {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(196, 196, 196, 0.7);
  z-index: 9;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 10000;
}
.loading_box > p {
  width: 280px;
  background: #ffffff;
  padding: 10px 5px;
  text-align: center;
  border: 1px solid #00a5d9;
  box-sizing: border-box;
  border-radius: 6px;
  margin-top: -50px;
}
.inactive{
  color:var(--light-grey-6);
}
@media screen and (max-width: 767px) {
  /* p.label {
        width: 50px;
        text-align: center;
    } */
}
@media screen and (max-width: 1200px) and (min-width: 999px) {
  .AS_code_dropdown {
    max-width: 100%;
    flex-basis: 80%;
  }
  .unique_code_dropdown {
    max-width: 100%;
    flex-basis: 50%;
  }
}