
.no_btn {
    background-color: var(--pure-white);
    color: var(--cerulean);
    border: 1px solid var(--cerulean);
    text-transform: uppercase;
    border-radius: 12px;
    font-weight: bold;
    margin: 0px;
    width: 100%;
    background-color: var(--alice-blue);
}
.MuiDialogActions-spacing > :not(:first-child){
    margin-left: 0px !important;
}