
.cmn-btn {
    width: 90px;
    background-color: var(--cerulean);
    padding: 5px;
    color: var(--pure-white);
    text-transform: uppercase;
    border-radius: 3px;
}
.btn-white {
    background-color: var(--pure-white);
    color: var(--cerulean);
    border: 1px solid #979797;
}
.general_dropdown_box {
    position: relative;
}
.general_dropdown_box:before {
    content: "";
    position: absolute;
    top: 16px;
    right: 2px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #999 transparent transparent transparent;
}
.general_dropdown03 {
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align-last:center;
    -moz-text-align-last: center;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 0px;
}
.general_dropdown03>div {
    display: block !important;
}
.general_dropdown03 span {
    padding: 0px 20px;
}
.general_dropdown03 img {
    margin-top: 0px;
}
.general_dropdown03 ul {
    top: 18px;
}
.grey{
background: #f2f2f2;
}