@charset "UTF-8";
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
@font-face {
  font-family: 'MeiryoRegular';
  src: local('MeiryoRegular'),
    url(./assets/fonts/Meiryo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MeiryoBold';
  src: local('MeiryoBold'),
    url(./assets/fonts/Meiryo-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoRegular';
  src: local('RobotoRegular'),
    url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold'),
    url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: MeiryoRegular, RobotoRegular, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #333;
  font-size: 14px;
  line-height: 1.5;
}

:root {
  --dune: #333333;
  --granite: #808080;
  --white-lilac: #f6f7f9;
  --grey-goose: #d1d1d1;
  --light-grey: #fafafa;
  --light-grey-2: #f2f2f2;
  --light-grey-3: #d9d9d9;
  --alice-blue: #e9faff;
  --pure-white: #ffffff;
  --cerulean: #00a5d9;
  --ocean: #037599;
  --apple-green: #82c43c;
  --leaf: #69a926;
  --dirty-orange: #cb7200;
  --watermelon: #ff485a;
  --light-grey-0: #f5f5f5;
  --light-grey-4: #d1c7c7;
  --light-grey-5: #e6e6e6;
  --light-grey-6: #b2b2b2;
  --error-msg: #e2242c;
  --yellow: #e98300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MeiryoRegular {
  font-family: 'MeiryoRegular';
}

.MeiryoBold {
  font-family: 'MeiryoBold';
}

.RobotoRegular {
  font-family: 'RobotoRegular';
}

.RobotoBold {
  font-family: 'RobotoBold';
}

a,
button,
select {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}
p {
  margin: 0 0 8px 0;
}
button p {
  margin: 0;
}
button {
  outline: none;
  border: 0;
  text-align: center;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
}
/*------------------ font size --------------------- */
h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-28 {
  font-size: 28px;
}
/*------------------ font color --------------------- */
.font-orange {
  color: var(--dirty-orange);
}
.font-label {
  color: var(--granite);
}
input::placeholder {
  color: var(--granite);
  /* font-size: 12px; */
}
input:focus {
  /* border: 1px solid var(--cerulean);
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: var(--pure-white); */
}
/*--------- Main Content------------*/
#root {
  height: auto;
  min-height: 100%;
  width: 100%;
  position: relative;
  display: flex;
}
#main-content {
  /* margin-top: 10px;
  padding-bottom: 10px; */
}

.main-content-inr {
  /* padding: 32px; */
  padding: 42px 32px 32px 32px;
}
/* .active {
  background-color: #027EC3;
} */
/*------------------ cmn lougout sec  --------------------- */
.cmn-logout-btn-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.cmn-logout-btn-sec h3 {
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: bold;
}
/*------------------ cmn table --------------------- */
.table td,
.table th {
  vertical-align: middle;
}
.cmn-table {
  width: 100%;
  background-color: var(--pure-white);
  margin-bottom: 0;
}
.cmn-table tr th {
  background-color: #f2f2f2;
}
.cmn-table tr th,
.cmn-table tr td {
  padding: 8px;
  border: 1px solid #d9d9d9;
  /* white-space: nowrap; */
}
.cmn-table tr td {
  vertical-align: top;
}
.cmn-table tr td.star-rating {
  white-space: nowrap;
}

.cmn-table tr th:first-child {
  /* width: 100px; */
}
.cmn-table .text-primary {
  color: #00a5d9 !important;
}
.cmn-table-scroll {
  max-height: 450px;
  overflow-y: auto;
}
.cmn-table-scroll thead {
  background-color: #fff;
}
.cmn-table-scroll thead th {
  position: sticky;
  border-top: 0 !important;
  border-bottom: 0 !important;
  z-index: 10;
  white-space: nowrap;
}
.cmn-table-scroll thead tr:nth-child(1) th {
  top: -1px;
}
.cmn-table-scroll thead tr:nth-child(2) th {
  top: 36px;
}
.cmn-table-scroll thead .no-fill:before,
.cmn-table-scroll thead .no-fill:after {
  content: initial !important;
}
.cmn-table-scroll thead tr:first-child th:before,
.cmn-table-scroll thead tr:last-child th:before,
.cmn-table-scroll thead tr:last-child th:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  position: absolute;
  left: 0;
}
.cmn-table-scroll thead tr:first-child th:before,
.cmn-table-scroll thead tr:last-child th:before {
  top: 0;
}
.cmn-table-scroll thead tr:last-child th:after {
  bottom: 0;
}
.hover-bottom .cmn-description {
  bottom: 29px;
  top: initial;
}
.td-140 {
  width: 140px;
}
.td-120,
.date-td {
  width: 120px;
}
.td-90 {
  width: 90px;
}
.td-60 {
  width: 60px;
}
.no-fill {
  background-color: #fff !important;
  border: 0 !important;
}
.no-fill-bg {
  background-color: #b2b2b2 !important;
}
.w-95 {
  width: 95%;
}

.vertical-align-middle-table td {
  vertical-align: middle !important;
}
.font-white {
  color: var(--pure-white);
}
/*---------Common Box ------------*/
.cmn-bg-box {
  background-color: #fafafa;
  padding: 32px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}
.cmn-bg-box-inr {
  width: 100%;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
/*--------- Common ------------*/
.no-border-radius {
  border-radius: 0;
}

/*--------- Common ------------*/
/* .main-content-inr .cmn-bg-box>.row {
  margin-bottom: 32px;
}
.main-content-inr .cmn-bg-box .cmn-bg-box-inr>div,
.main-content-inr .cmn-bg-box>.row>div>.cmn-bg-box-inr>.row,
.main-content-inr .cmn-bg-box>.row>div>.cmn-bg-box-inr>div {
  margin-bottom: 24px;
}
.main-content-inr .cmn-bg-box .cmn-bg-box-inr>div:last-child
.main-content-inr .cmn-bg-box>.row>div>.cmn-bg-box-inr>.row:last-child,
.main-content-inr .cmn-bg-box>.row>div>.cmn-bg-box-inr>div:last-child,
.main-content-inr .cmn-bg-box>.row:last-child {
  margin-bottom: 0;
} */
/* .small-padding-box>div:nth-child(odd) {
  padding-right: 12px;
}
.small-padding-box>div:nth-child(even) {
  padding-left: 12px;
}
.large-padding-box>div {
  margin-bottom: 32px;
}
.large-padding-box>div:nth-child(odd) {
  padding-right: 16px;
}
.large-padding-box>div:nth-child(even) {
  padding-left: 16px;
} */
/*--------- Margin ------------*/
.smallest-padding-box8 {
  margin-left: -8px;
  margin-right: -8px;
}
.smallest-padding-box8 > div {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.p-32 {
  padding: 32px;
}
.MuiDialog-container .MuiPaper-root {
  border-radius: 12px !important;
  /* top: -8px;  */
  margin: 0 !important;
}
.MuiDialog-paperScrollPaper {
  max-height: calc(100% - 50px) !important;
}
.img-popup .MuiDialog-container .MuiPaper-root {
  border-radius: 0 !important;
}
.MuiCollapse-container {
  margin-right: 10px;
}
.Mui-disabled span:before {
  background-image: radial-gradient(#b2b2b2, #b2b2b2 32%, transparent 37%);
}
.font-black {
  color: #000;
}

.card-bg,
.card-header {
  background-color: var(--light-grey-2);
}
.button-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.MuiChip-root {
  height: auto !important;
  margin: 0 8px 4px 0;
}
.MuiChip-root:last-child {
  margin-right: 0;
}
.MuiChip-root > span {
  padding: 1px 24px 2px;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.MuiAccordionSummary-content {
  margin: 16px 0 !important;
}

.text_underline {
  text-decoration: underline;
}
.bg-blue,
.progress-bar {
  background-color: #00a5d9 !important;
}
.bg-gray {
  background-color: #b2b2b2 !important;
}
span.delete {
  font-family: Meiryo-Regular;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: var(--cerulean);
  background: #ffffff;
  padding: 0px 8px;
  border: 1px solid #979797;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 43px;
}
/*-----------------------------------------------
Edit Keywords and Keyword Selection Pages 
-----------------------------------------------*/
.edit-keywords-accordian .MuiCollapse-container {
  margin-right: 0;
}
.keyword-edit-sec {
  /* padding-right: 40px; */
}
.edit-keywords-accordian .MuiAccordion-rounded {
  border-radius: 0 !important;
}
.edit-keywords-accordian .MuiAccordion-root {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}
.edit-keywords-accordian .MuiAccordion-root:before {
  background: initial !important;
}
.keyword-or-box .MuiCheckbox-root span span {
  border-color: #00a5d9;
}
.keywords-accor-summary-inr {
  padding: 0 32px !important;
}
.keywords-accor-summary-inr[aria-expanded='true'] {
  border-bottom: 1px solid #d9d9d9;
}
.keywords-accor-summary-detail {
  padding: 24px 32px !important;
  display: block !important;
}
.edit-keywords-accordian {
  margin-bottom: 24px;
}
.edit-keywords-accordian .MuiAccordion-root:last-child,
.edit-keywords-accordian:last-child {
  /* margin-bottom: 0 !important; */
}
.edit-keywords-accordian .MuiAccordion-root {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}
.edit-keywords-accordian .MuiAccordion-root:before {
  background: initial !important;
}
.edit_setting_page .MuiAccordionSummary-root {
  height: 50px !important;
}
/*-----------------------------------------------
end of Edit Keywords and Keyword Selection Pages 
-----------------------------------------------*/

.cmn-scroll-bar {
  max-height: 450px;
  overflow-y: auto;
}
.cmn-scroll-bar::-webkit-scrollbar {
  width: 16px; /* width of the entire scrollbar */
}
.cmn-scroll-bar::-webkit-scrollbar-track {
  background: #c4c4c4; /* color of the tracking area */
}
.cmn-scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--pure-white); /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 3px solid #c4c4c4; /* creates padding around scroll thumb */
}

.cmn-scroll-bar-blue::-webkit-scrollbar-track {
  background: #f3f3f3;
}
.cmn-scroll-bar-blue::-webkit-scrollbar-thumb {
  background-color: var(--cerulean);
  border-color: #f3f3f3;
}

.keyword-or-box .MuiTypography-body1 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.scroll-bar-height-600 {
  max-height: 600px;
}
.cmn-scroll-bar-box {
  width: 100%;
  border-radius: 10px;
}
.cmn-scroll-bar-box > h5 {
  background: #d1d1d1;
  padding: 16px 8px;
  margin-bottom: 0;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
}
.cmn-scroll-bar-02 {
  border-top-left-radius: 0;
  background-color: var(--pure-white);
  max-height: 520px;
  overflow-y: auto;
  padding: 0 0 24px;
  border-radius: 10px;
}
.cmn-scroll-bar-02::-webkit-scrollbar {
  width: 16px; /* width of the entire scrollbar */
  border-bottom-right-radius: 10px;
}
.cmn-scroll-bar-02::-webkit-scrollbar-track {
  background: #e6e6e6; /* color of the tracking area */
  border-bottom-right-radius: 10px;
}
.cmn-scroll-bar-02::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}
.cmn-scroll-inr {
  padding: 24px 40px 32px 24px;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.3);
}
.cmn-scroll-inr:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

/*--------- styled scrollbar ------------*/
.styled_scrollbar::-webkit-scrollbar-track {
  background-color: var(--light-grey-0);
}
.styled_scrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: var(--light-grey-0);
}
.styled_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--cerulean);
}
@media screen and (min-width: 576px) {
  /* .row {
    margin-left: -12px;
    margin-right: -12px;
  }
  .row>div {
    padding-right: 12px;
    padding-left: 12px;
  } */
  .smallest-padding-box {
    margin-left: -12px;
    margin-right: -12px;
  }
  .smallest-padding-box > div {
    padding-right: 12px;
    padding-left: 12px;
  }
  .small-padding-box {
    margin-left: -16px;
    margin-right: -16px;
  }
  .small-padding-box > div {
    padding-right: 16px;
    padding-left: 16px;
  }
  .medium-padding-box {
    margin-left: -20px;
    margin-right: -20px;
  }
  .medium-padding-box > div {
    padding-right: 20px;
    padding-left: 20px;
  }
  .large-padding-box {
    margin-left: -20px;
    margin-right: -20px;
  }
  .large-padding-box > div {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .font-12 {
    font-size: 10px;
  }

  .font-14 {
    font-size: 12px;
  }

  .font-16 {
    font-size: 14px;
  }

  .font-18 {
    font-size: 16px;
  }

  .font-20 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
 /*  .font-10 {
    font-size: 10px;
  }*/

  /* .font-12 {
    font-size: 12px;
  } */

  /*.font-14 {
    font-size: 14px;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-20 {
    font-size: 20px;
  }*/
} 

@media screen and (min-width: 1920px) {
  .font-10 {
    font-size: 12px;
  }

  .font-12 {
    font-size: 14px;
  }

  .font-14 {
    font-size: 16px;
  }

  .font-16 {
    font-size: 18px;
  }

  .font-18 {
    font-size: 20px;
  }

  .font-20 {
    font-size: 28px;
  }
}

@media screen and (max-width: 1199.98px) {
  .back-btn-box > button {
    width: 90px !important;
  }
}

@media screen and (max-width: 999.98px) {
  .no-overflow-table {
    overflow-x: auto !important;
  }
}
@media screen and (min-width: 1000px) {
  .no-overflow-table {
    overflow-x: initial !important;
  }
}
@media screen and (max-width: 1100px) and (min-width: 999px) {
  .timePicker {
    left: -96px !important;
  }
}

