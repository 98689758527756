.ai_or_popup_box {
  padding: 48px 56px 48px 40px;
  background: var(--pure-white);
  .alert_font {
    font-size: 18px;
    font-weight: bold;
  }
  .ai_or_box {
    min-height: 107px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    .pass {
      width: 100px;
      background: #e9faff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #00a5d9;
    }
    .unpass {
      width: 100px;
      background: #b2b2b2;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: var(--pure-white);
    }
    .keyword_body {
      width: 100%;
      padding: 16px 40px 8px 32px;
    }
  }
  .ai_or_label {
    width: auto;
    background: #b2b2b2;
    padding: 1px 4px 0;
    display: inline-block;
    margin: 0 8px 8px 0;
    font-size: 12px;
    text-align: center;
    color: #fff;
    border-radius: 15px;
    padding: 1px 12px;
  }
  .match_item {
    background-color: #00a5d9;
  }
  .ai_and_text {
    margin: 16px 0;
    text-align: center;
  }
}

@media screen and (min-width: 576px) {
  .ai_or_popup_box {
    width: 560px !important;
  }
}
