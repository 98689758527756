.scroll_header_box {
  display: flex;
}
.scroll_header_box > div:first-child {
  width: 308px;
}
.header_sec {
  padding: 32px;
  display: flex;
}
.header_sec>div:first-child {
  width: 273px;
}
.custom_keyword_chip {
  min-height: 37px;
  padding: 2px 15px;
  display: inline-flex;
  align-items: center;
}
.cmn_scroll_bar_xy {
  max-height: 460px;
  overflow: auto;
  padding: 24px 0;
  border-top: 1px solid #D9D9D9;
}
.full_width {
  min-width: 1200px;
}
.full_width > div > div:nth-child(2) {
  display: table-cell;
  white-space: nowrap;
  /* margin-left: 17px; */
}
.keyword_content {
  max-width: 306px;
  flex: 0 0 306px;
   padding-left: 32px;
   padding-right: 32px;
  position: sticky;
  left: 0;
  z-index: 9;
  background: var(--pure-white);
}
.keyword_card {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
  display: inline-flex;
  width: 100%;
  border-radius: 6px;
  min-height: 43px;
  align-items: center;
}
.txt_newSynonym {
  padding: 8px 32px 8px 16px;
  border-radius: 8px;
  margin-right: 10px;
  border: 1px solid #00a5d9;
  color: #00a5d9;
}
.body_top_border {
  /* border-top: 1px solid #D9D9D9; */
  /* padding: 24px 32px 8px; */
  position: relative;
}
.full_width:before {
    /* content: "";
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    left: 0;
    top: 0px;
    position: absolute;
    min-width: calc( 1200px + 44px); */
}
.reset_add_synonym{
    top: 2px;
    right: 20px;
    width: 18px;
    cursor: pointer;
}
.custom_content_chip {
  min-height: 43px;
}
.txt_newSynonym {
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  margin-right: 10px;
  border: 1px solid #00a5d9;
  color: #00a5d9;
}


.register_btn {
  background-color: var(--cerulean);
  padding: 8px 18px;
  color: var(--pure-white);
  text-transform: uppercase;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.cancel_btn {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}