.pt_label{
    display: flex;
    justify-content:space-between;
}
.MuiTypography-body1{
    /* font-size: 16px !important;
    font-weight: normal !important;
    margin-right: 48px !important;
    margin-left: 8px !important; */
}
.MuiFormControlLabel-root{
    /* margin: 0px !important; */
}

.bg_gray{
    background-color: #f2f2f2;
}
.panel_box {    
    min-height: 50px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
    display: inline-flex;
    width: 100%;
}
.arrow {
    /* background: #ccc; */
    position: relative;
    width: 16px;
    margin-top: -16px;
    height: 0;    
    white-space: pre-wrap;
  }
  .triangle {
    padding: 8px 6px;
  }
  .triangle.down {
    background: url(../../assets/images/sort_down.svg) center no-repeat;
  }
  .triangle.up {
    background: url(../../assets/images/sort_up.svg) center no-repeat;
  }
  .panel_box .first_child_btn {
    background: url(../../assets/images/top_sort_up.svg) center no-repeat !important;
  }
  
  .panel_box .last_child_btn {
    background: url(../../assets/images/bottom_sort_down.svg) center no-repeat !important;
  }
  .disable_div{
    height: 50px;
    padding: 8px 16px 8px 24px;
    /* background-color: #FBFBFB; */
    border: 1px solid #D6E4EC;
    border-radius: 5px;
    display: -webkit-box;
  }
  .disable_div>div{
    justify-content: space-between;
    align-items: center;
  }
  .down_arrow{
      width: 12px;
      /* margin-right: 6px; */
  }
  .edit-learning-theme-table tr td {
    padding: 16px;
    /* white-space: nowrap; */
  }
  .edit-theme-text-box {
    min-height: 50px;
    border-color: var(--light-grey-6);
    color: var(--dune);    
  }
  .edit-theme-select-box {
    height: 50px;
  }
  .edit-theme-select-box>div {
    height: 100%;
  }
  .bg_grayout{
    background-color: #f2f2f2;
  }
  .bg_grayout .panel_box{
    background-color: #f2f2f2;
  }
  .ant-picker-ok > button.ant-btn > span {
    font-size: 0;
  }
  .ant-picker-ok > button.ant-btn > span:after {
    content: "反映";
    font-size: 16px; /* original font size */
  }
  .target_time{
    width: 100%;
    height: 50px;
  }
  
  @media screen and (max-width: 1200px) {
    .edit-learning-theme-table {
      overflow-x: auto;
    }
    .edit-learning-theme-table td {
      white-space: nowrap;
    }
    .panel_box {
      width: 270px;
    }
    .edit-theme-text-box {
      width: 220px;
    }
    .edit-theme-select-box,
    .disable_div {
      width: 230px;
    }
  }
  