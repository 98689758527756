.details {
  color: var(--cerulean);
  font-size: 16px;
}

.customer_card {
  width: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: flex;
  /* flex-wrap: wrap; */
  padding: 8px 16px;
  min-height: 92px;
  height: auto;
  font-family: Roboto;
}
.customer_card_row > div:nth-last-child(1),
.customer_card_row > div:nth-last-child(2),
.customer_card_row > div:nth-last-child(3) {
  /* margin-bottom: 0 !important; */
}
.active {
  border: 1px solid #00a5d9;
}

.customer_card > img {
  margin-left: 10px;
  margin-right: 15px;
  width: 80px;
  height: 90px;
}
.grey_text {
  color: #b2b2b2;
}
@media screen and (max-width: 991.98px) {
  .customer_card_row > div:nth-last-child(3) {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 575.98px) {
  .customer_card_row > div:nth-last-child(2) {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 1420px) {
  .EN {
    flex-wrap: nowrap;
  }
}
