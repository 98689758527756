.edit_criteria {
  display: flex;
  align-items: center;
}
.edit_criteria_checkbox,
.edit_criteria_content {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.edit_criteria_checkbox {
  text-align: center;
}
.edit_criteria_content {
  display: inline-flex;
}
.arrow {
  /* background: #ccc; */
  position: relative;
  width: 16px;
  margin-top: -16px;
  height: 0;
}
.triangle {
  padding: 8px 6px;
}
.triangle.down {
  background: url(../../assets/images/sort_down.png) center no-repeat;
}
.triangle.up {
  background: url(../../assets/images/sort_up.png) center no-repeat;
}
.dropArea {
  color: white !important;
  background: white !important;
  position: relative;
}
.dropArea::before {
  content: 'Drop Here';
  color: #687bf7;
  font-size: 0.5em;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  border: 2px dashed #687bf7;
  border-radius: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropArea span {
  display: none;
}

.dropArea p {
  margin-left: 1em;
}

ul .order_list:hover {
  background: linear-gradient(to left, #687bf7, #72d9ef);
  color: white;
}
ul .order_list:hover span {
  background-color: #79e7eb;
}
ul li:hover p {
  transition: margin-left 50ms ease-in-out;
}

ul .order_list:hover i {
  margin-left: auto;
  margin-right: 1em;
}

ul .order_list span {
  display: inline-block;
  margin-right: 1em;
  margin-left: 0.5em;
  background-color: #72d9ef;
  width: 2em;
  /* height: 2em; */
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
  font-weight: bold;
  transition: background-color 100ms linear;
}
ul .order_list {
  display: flex;
  align-items: center;
  cursor: move;
  line-height: 1;
  margin-bottom: 0.1em;
  border-radius: 5px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.mandatory {
  font-family: Meiryo-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: var(--cerulean);
  background: #ffffff;
  padding: 0px 8px;
  border: 1px solid #979797;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 45px;
  justify-content: center;
}
.add {
  /* padding-right: 30px; */
  display: flex;
  align-items: center;
}
.add .mandatory {
  width: 60px;
  color: var(--pure-white);
  background: var(--cerulean);
  padding: 3px 4px;
}
.add > div > div {
  width: calc(100% - 77px);
}
.add input,
.add input:focus,
.add input:hover {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
  padding: 0px !important;
}
.required {
  background: #ffe9e9;
}
.required p,
.deleted p,
.added p {
  /* font-weight: 700; */
}

.section .edit_criteria:first-child .triangle.up {
  background: url(../../assets/images/top_sort_up.png) center no-repeat !important;
  pointer-events: none;
}

.section .edit_criteria:nth-last-child(2) .triangle.down {
  background: url(../../assets/images/bottom_sort_down.png) center no-repeat !important;
  pointer-events: none;
}

.edit_criteria_textbox {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}
.edit_criteria_textbox:focus {
  box-shadow: none !important;
}

.deleted {
  background: #ffe9e9;
}

.added {
  background: #e9faff;
}
.delete_btn {
  background: var(--cerulean);
  color: var(--pure-white);
}
@media screen and (max-width: 767.98px) {
  .add,
  .edit_criteria {
    width: 400px;
  }
}
