.list_of_scenario {
  .MuiAccordion-root.Mui-disabled {
    background-color: #fff;
  }
  .MuiAccordionSummary-root.Mui-disabled {
    opacity: inherit;
  }
  .section_collapse {
    box-shadow: none !important;
  }
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
  }
  .MuiTypography-body1 {
    height: auto;
    line-height: 38px;
    width: 100%;
    margin-right: 0 !important;
  }
  .MuiPaper-root {
    width: 100%;
  }
}
