.circle {
    display : inline-block;
    border-radius : 50%;
    width: 40px;
    height: 40px;
    left : 0;
    top: 0;
    background-color: #B2B2B2;
    display: flex;
    justify-content :center;
    align-items :center;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}
.line {
    width: 112px;
    height: 3px;
    /* background: #00A5D9; */
}
.active {
     background: var(--cerulean);
}
.inactive {
    background: var(--light-grey-6);
}
@media screen and (max-width: 475px) {
    .line {
        width: 30px !important;
    }
    .circle {
        width: 30.53px;
        height: 30.53px;
    }
}
@media screen and (max-width: 500px) {
    .line {
        width: 50px !important;
    }
}
@media screen and (max-width: 767px) {
    .line {
        width: 100px;
    }
}

