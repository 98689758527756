.root{
    width: 100%
  }
.accordianHead{
  margin-bottom : 8px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%) !important;
}
.MuiAccordion-rounded:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.MuiAccordion-rounded:last-child {
  border-bottom-left-radius : 0px !important;
  border-bottom-right-radius : 0px !important;
  margin-bottom : 0px;
}
.MuiAccordion-rounded:before {
  position: initial;
}
.heading{
  width : 70%;
}
.secondaryHeading{
  width : 30%;
}
.accordiansummaryHead{
  border-bottom : 1px solid #e6e6e6 !important;
  height: 64px;
}
.accordianDetails{
  padding : 16px;
}
.section_name{
  display :  inline-block;
  width : 250px;
}
.scoring{
  display :  inline-block;
  border : 1px solid #00A5D9;
  color : #00A5D9;
  border-radius : 8px;
}
.match_item {
  background-color : #00A5D9 !important;
  color : #fff !important
}
.not_match_item {
  background-color : #B2B2B2 !important;
  color : #fff !important
}
.ai_dialog_text {
  font-weight: bold;
  color: #00A5D9;
  text-decoration: underline;
  cursor: pointer;
}