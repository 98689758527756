.scrollbar > div {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: center;
}
.scrollbar > div::-webkit-scrollbar-track {
  background-color: var(--light-grey-0);
}
.scrollbar > div::-webkit-scrollbar {
  width: 12px;
  background-color: var(--light-grey-0);
}
.scrollbar > div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--cerulean);
}
.list {
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 100;
  left: 0;
}

.square {
  width: 16px;
  height: 16px;
}
