.cmn_btn02 {
    background: #E98300;
    padding: 16px 32px;
    color: var(--pure-white);
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 28px;
    margin-bottom: 32px;
}
.cmn_btn02.btn-large {
    width: 234px;
}
.cmn_btn02.btn-small {
    width: 162px;
}