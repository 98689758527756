.title {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--pure-white);
}

.finish_button {
    background: #E9FAFF;
    border: 1px solid #00A5D9;
    box-sizing: border-box;
    border-radius: 32px;
    color: var(--cerulean);
    text-transform: uppercase;
    padding : 16px 32px;
}


