.MuiDialog-paperWidthSm {
  max-width: 800px !important;
}

.popup_content {
  margin: 32px 16px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items:start;
}

.popup_content > img {
  margin-bottom: 16px;
}
.popup_content > button {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.popup_content > button:hover {
  text-decoration: underline;
}
