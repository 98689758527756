.conversation_button {
  width: 100%;
  background: #fbfbfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border: 1px solid #d6e4ec;
  padding: 8px 16px 8px 24px;
  border-radius: 5px;
}
.conversation_button .icon {
  background: url(../../../assets/images/down_arrow.png) no-repeat center center;
  width: 12px;
  height: 8px;
  cursor: pointer;
  background-size: contain;
}
.conversation_button .icon.show {
  background: url(../../../assets/images/up_arrow.png) no-repeat center center;
  background-size: contain;
}
.conversation_content {
  margin-top: -2px;
  display: none;
  background: #fbfbfb;
  border: 0.678715px solid #d6e4ec;
  padding: 16px 24px;
}

.conversation_content.show {
  display: block;
}

.feedBackContent {
  min-height: 260px;
}
.good_point_height{
  min-height: 420px;
  max-height: 420px;
}
.improvement_point_height{
  min-height: 420px;
  max-height: 420px;
}
.cMatter_point_height{
  min-height: 225px;
  max-height: 225px;
}