.tabs {
  padding: 0;
}
.tabs > ul {
  padding: 0;
}

.tabs_titles {
  list-style: none;
  padding: 0px;
  margin: 0;
  display: flex;
}

.tab_title {
  display: inline-block;
  padding: 11px 8px;
  cursor: pointer;
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  font-weight: 700;
}

.tab_title__active {
  background-color: var(--cerulean);
  color: var(--pure-white);
}

.tab_content {
  display: flex;
}
.header {
  /* max-width: 140px !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tab_dropdown {
  background: #fbfbfb;
  border: 0.678715px solid #d6e4ec;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(143, 143, 143, 0.15);
  border-radius: 5px;
}
.scenario_dropdown > div {
  padding: 6px 16px 6px 24px !important;
}
.scenario_dropdown > div ul {
  margin-top: 7px !important;
}
@media screen and (max-width: 768px) {
  .tabs_titles,
  .tab_content {
    flex-direction: column;
  }
  .header {
    display: block;
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 1200px) and (min-width: 999px) {
  .scenario_dropdown {
    max-width: 100%;
    flex-basis: 50%;
  }
}
