.forgot_password_page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    position: relative;
}

.root {
    width: 520px;
    padding: 0 !important;
    margin: 0 !important;
}

.main {
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    top:12px;
}

.closeButton {
    display: flex;
    justify-content: flex-end;
    padding: 0 !important;
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 999;
    width: auto;
}

.close_icon {
    /* padding-top: 16px !important;
    padding-right: 16px !important; */
}

.center_container {
    /* padding: 42px 48px 48px 48px; */
    padding: 48px 48px 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 10px;
    z-index: 1;
}

.title {
    width: 100%;
    margin: 0;
    text-align: left;
    color: #333333;
    line-height: 42px;
}

.form_container {
    width: 100%;
}

.textbox_label {
    padding-top: 24px;
    color: #808080;
}

.text_box {
    /* padding-bottom: 24px; */
    padding-left: px;
    padding-right: 0px;
}

.text_box>input {
    min-height: 64px;
}

.btn_reset_password {
    margin-top: 48px;
    width: 100%;
    background: #00A5D9;
    border: 2px solid #037599;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 5px;
    min-height: 64px;
}

.border_danger>input {
    border: 1px solid #E2242C !important;
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    border-radius: 6px !important;
}

.show_user>img,
.show_pass>img {
    display: inline-block !important;
}


@media screen and (max-width: 575.98px) {

    .root {
        width: 100%;
    }
}