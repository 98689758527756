#login_page {
  min-height: 100%;
  height: 100%;
  background-color: var(--white-lilac);
  min-width: 100%;
  background: url(../../assets/images/kosta-bratsos-lBPgzz3HGIo-unsplash.jpg)
    no-repeat top center;
  flex-direction: column;
  background-size: cover;
  position: relative;
}

#login_page::before {
  content: '';
  background: rgba(0, 165, 217, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.login_header {
  position: sticky;
  top: 0;
  background: initial;
}

.login_page {
  min-height: 100vh;
  /* min-height: calc(100vh - 50px); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  flex-direction: column;
  background-size: cover;
  position: relative;
}

.cancel_icon {
  z-index: 2;
  background: rgba(255, 255, 255, 0);
}

.cancel {
  display: none !important;
}

.message_container {
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  position: initial;
  width: 100%;
  padding: 32px 32px 16px 16px;
}

.message_content {
  background: #ffffff;
  border-radius: 10px;
  padding: 8px;
  margin-top: -12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.message_content > img {
  width: 40.33px;
  height: 40.33px;
}

.message_content > p {
  padding: 0 16px;
  margin: 0;
}

.center_container {
  width: 520px;
  padding: 40px 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  z-index: 1;
  /* margin-top: -50px; */
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

.logo {
  width: 124px;
  /* height: 66px; */
}

.title {
  padding: 16px 16px 24px;
  /* width: 100%; */
  margin: 0;
  text-align: center;
  /* color: #333333; */
  border-bottom: 1px solid #f5f5f5;
  line-height: 28px;
}

.form_container {
  width: 100%;
}

.textbox_label {
  margin-top: 24px;
  color: #808080;
}

.text_box > input {
  min-height: 64px;
}

.forgot_password {
  margin: 16px 0 32px;
  color: var(--yellow);
}

.btn_login {
  width: 100%;
  background: #00a5d9;
  border: 2px solid #037599;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8px;
  min-height: 64px;
}

.border_danger > input {
  border: 1px solid #e2242c !important;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  border-radius: 6px !important;
}

.show_user > img,
.show_pass > img {
  display: inline-block !important;
}

/* .open~.center_container {
    margin-bottom: 32px;
} */

@media screen and (max-width: 575.98px) {
  .center_container {
    width: 90%;
    margin: 0 auto 0;
  }
}
