.selection_recruiter_box {
  background: var(--pure-white);
  border: 1px solid var(--light-grey-3);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0px;
}
.selection_recruiter_header {
  background: var(--light-grey-2);
  padding: 24px 32px;
  border-bottom: 1px solid var(--light-grey-3);
  color: black;
  height: 70px;
  line-height: middle;
}
.selection_recruiter_body {
  height: 300px;
  overflow-y: auto;
}
.selection_recruiter_body::-webkit-scrollbar {
  width: 16px; /* width of the entire scrollbar */
}
.selection_recruiter_body::-webkit-scrollbar-track {
  background: var(--light-grey-2); /* color of the tracking area */
}
.selection_recruiter_body::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 3px solid #c4c4c4; /* creates padding around scroll thumb */
}

.selection_recruiter_body > div {
  border-bottom: 1px solid var(--light-grey-3);
  padding: 20px 32px;
  height: 60px;
  line-height: middle;
}
.gray {
  background: var(--light-grey-6) !important;
  border-color: var(--light-grey-6) !important;
}
@media screen and (max-width: 1200px) and (min-width: 999px) {
  .selection_recuiter_dropdown {
    max-width: 100%;
    flex-basis: 50%;
  }
}
