.keyword_sec {
  margin-bottom: 64px;
}
.label { 
  padding: 13px 24px;
  white-space: nowrap;
  overflow: hidden;
}
.label_info {
  background: var(--pure-white);
  border: 1px solid #d9d9d9;
  padding: 13px 24px;
  text-align: right;
}
.keyword {
  padding:0px;
  margin-bottom: 16px;
  border-radius: 6px;
  display: flex;
}
.keyword_title {
  width: 80%;
  padding: 11px 32px;
}
.keyword_title > span,
.keyword_percentage > span {
  display: inline;
  vertical-align: middle;
  position: relative;
}
.keyword_percentage {
  background: var(--alice-blue);
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.keyword_expend {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
}
.keyword_accuracy {
  display:flex;
}
.keyword_accuracy > .label:first-child {
  min-width: 158px;
}