.input_group {
  display: flex;
  border: 2px solid #ffffff;
}
.exam_in_progress_time_box .input-group-text {
  background: var(--light-grey-2);
}
.exam_in_progress_time_box .input-group-text,
.exam_in_progress_time {
  padding: 12px 16px;
}
.exam_in_progress_time {
  width: 100px;
  border: 1px solid #d9d9d9;
  text-align: right;
  color: var(--dune);
}
.exam_in_progress_time::placeholder {
  color: var(--dune);
  /* font-size: 12px; */
}
.exam_in_progress {
  padding-bottom: 8px;
}
.exam_in_progress::-webkit-scrollbar {
  width: 12px;
}
.exam_in_progress::-webkit-scrollbar-track {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.exam_in_progress_row {
  margin-bottom: 8px;
}
.progress_box {
  width: 100%;
  height: 52px;
  background: var(--pure-white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 8px 8px 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border: 2px solid #fff;
}
.progress_box > label {
  width: 62%;
  margin-bottom: 0;
}

.finish_button {
  color: var(--pure-white);
  background: var(--cerulean);
}
.time {
  width: 33%;
  border: none;
}
.required {
  background: #ffe9e9;
  border: 2px solid #ffe9e9;
}
.validate {
  border: 2px solid red;
}

.exam > div {
  font-weight: bold;
  height: 38px;
  font-family: RobotoRegular;
  font-size: 20px;
  line-height: 0px;
  margin-bottom: 0px !important;
}
.failed_btn > div {
  font-size: 30px;
}
@media screen and (max-width: 1200px) {
}
