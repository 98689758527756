.history {
  background: red;
  .MuiIconButton-root {
    padding: 0 !important;
    margin: 20px !important;
  }
  .mate_btn {
    color: #00a5d9 !important;
    background-color: #00a5d933 !important;
    border: 1px solid #00a5d9 !important;
    box-shadow: none !important;
    box-sizing: border-box;
    padding: 8px 16px;
    height: 38px;
    line-height: 38px;
    border-radius: 8px !important;
    width: 100px;
  }
  .mate_btn:hover {
    box-shadow: none !important;
  }
  .mate_btn_primary {
    background-color: #00a5d9 !important;
    border: 1px solid #00a5d9 !important;
    box-shadow: none !important;
    box-sizing: border-box;
    padding: 8px 16px;
    height: 38px;
    line-height: 38px;
    border-radius: 8px !important;
    width: 100px;
  }
  .mate_btn_primary:hover {
    box-shadow: none !important;
  }
  .gray_background {
    background: #f2f2f2;
  }
}
