.slider {
  box-sizing: border-box;
  border-radius: 2px;
  margin: 4px;
  margin-bottom: 0.5rem;
}
.slider_titles {
  display: flex;
  height: 100%;
}
.slider_title {
  font-family: Meiryo-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  color: var(--light-grey-6);
  padding: 4px;
  border: 1px solid #00a5d9;
  /* border-radius: 2px; */
  cursor: pointer;
}
.slider_title:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-left: 8px;
  padding-right: 8px;
}
.slider_title:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.tab_title__active:last-child {
  margin-right: -1px;
}

.tab_title__active {
  background: var(--cerulean);
  border-radius: 1px 0px 0px 1px;
  color: var(--pure-white);
  border: 1px solid var(--cerulean);
}
