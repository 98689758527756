.edit_criteria {
  display: flex;
  align-items: center;
}
.edit_criteria_checkbox,
.edit_criteria_content {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.edit_criteria_checkbox {
  text-align: center;
}
.edit_criteria_content {
  display: inline-flex;
}
.arrow {
  /* background: #ccc; */
  position: relative;
  width: 16px;
}
.triangle {
  position: absolute;
  border: solid var(--dune);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 2px;
  height: 5px;
}
.triangle.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 14px;
}
.triangle.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.criteria_box {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 11px 28px!important;
  height: 50px;
  display:flex;
  align-items: center;
}
.grey {
  background: var(--light-grey-2);
}
@media screen and (max-width: 1200px) and (min-width: 999px) {
  .AS_code_dropdown {
    max-width: 100%;
    flex-basis: 50%;
  }
}
