.ai_score_detail {
    padding: 24px 0;
}
.ai_score_detail_up {
    border-bottom:  1px solid #E6E6E6;
}
.ai_score_detail_table tr th,
.ai_score_detail_table tr td {
    vertical-align: middle;
}
.general_dropdown03 {
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align-last:center;
    -moz-text-align-last: center;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 0px;
}
.general_dropdown03 span {
    padding: 0px 20px;
}
.ai_score_detail_table ul{
    margin-top : 5px;
}
.match_item{
    background-color : #00A5D9 !important;
    color : #fff !important;
}
.not_match_item{
    background-color : #B2B2B2 !important;
    color : #fff !important;
  }