.ai_header_section{
    align-items: center;
    margin-bottom: 40px;
}
.recording_state{
    border: 1px solid #E98300;
    padding: 5px;
    color: #E98300;
    border-radius: 4px;
}
.recording_icon{
    width: 20px;
}
.ai_btn_box button:first-child { 
    padding-left: 0;
    padding-right: 0;
}
.talk_txt{
    -webkit-transform: rotate(-90deg);
    top: 30%;
    left: 10px;
    color: #00A5D9;
}
.listen_txt{
    -webkit-transform: rotate(-90deg);
    bottom: 30%;
    color: #E98300;
    ;
}
.talklistchart_box {
    height: 300px;
    overflow-x: scroll;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
}
.talklistchart_box::-webkit-scrollbar-track {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
@media screen and (max-width: 1199.98px) {
    .ai_btn_box button:first-child {
        width: auto !important;
        display: block;
        padding-left: 24px;
        padding-right: 24px;
    }
}