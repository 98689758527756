@media screen and (max-width: 767.98px) {
    .trainingplan_table {
        overflow-x: auto;
    }
    .trainingplan_table th,
    .trainingplan_table td {
        white-space: nowrap;
    }
}
@media screen and (min-width: 768px) and (max-width: 1300px) {
    .td_last_month_picker ul li>div>div>div:last-child>div:last-child>div {
        right: -120px;
    }
}

.mb120 {
    margin-bottom: 120px;
}
.active_user {
    background:var(--cerulean);
    color:var(--pure-white);
}
.active_picker {
    background:var(--alice-blue);
}
.warning_month {
    background: #FDBC15;
    /* opacity: .8; */
}