.table-filter {
  border: 5px solid;
  border-color: #999 transparent transparent transparent;
  align-self: flex-end;
  margin-left: 15px;
  cursor: pointer;
}

.star-rating img {
  margin-right: 2px;
}

.study-theme-table tr th:first-child,
.study-theme-table tr td:first-child {
  width: 100px;
  background-color: #f2f2f2;
}

.study-theme-table tr th,
.study-theme-table tr td {
  width: 150px;
}
.step-table tr td:first-child {
  width: 100px;
  background-color: #f2f2f2;
}

.learning-theme-dropdown {
  background: #fbfbfb !important;
  border: 0.678715px solid #d6e4ec !important;
}
.cmn_inner_box {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.mx-12 {
  margin-right: 12px;
  margin-left: 12px;
}
.AS_code_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.seven_code_dropdown {
  /* right: 5px; */
}
.AS_code {
  min-width: 400px;
}
#recruiter-summary label {
  /* height: 32px; */
}
.three_code_dropdown {
  padding-left: 8px;
  padding-right: 8px;
}
.study_theme_shadow {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
}
@media screen and (max-width: 767px) {
  .w-95 {
    width: 100%;
  }

}
@media screen and (max-width: 991px) {
  .AS_code {
    width: 100%;
  }
  .smallest-padding-box div {
    margin-bottom: 8px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .theme_section {
    max-width: 70.333333%;
    flex: 0 0 70.333333%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 999px) {
  .summary_multi_select_dropdown ,
  .learning_theme_dropdown,
  .recruiter_name_dropdown{
    max-width: 100%;
    flex-basis: 50%;
  }
  .summary_single_select_dropdown {
    max-width: 100%;
    flex-basis: 100%;
  }
}