.theme_table tr td:nth-child(2) {
  text-align: left;
  padding-left: 22px;
}

.theme_table tr th:first-child {
  font-weight: bold;
  text-align: left;
  padding-left: 22px;
  background: #f2f2f2;
}

.tableFixHead td:first-child, .tableFixHead th:first-child {
  position:sticky;
  left:0;
  z-index:500 ;
}.tableFixHead th:nth-child(2)  { 
position:sticky;
  left:155px;
  z-index:500 ;
  }
.tableFixHead td:nth-child(2)  { 
position:sticky;
  left:155px;
  z-index:500 ;
  background-color:white;
  }
.tableFixHead th {
  position: sticky;
  top: 0;
}
.tableFixHead {
  max-height: 450px;
  overflow-y: auto;
}
.tableFixHead::-webkit-scrollbar {
  width: 16px; /* width of the entire scrollbar */
  border-bottom-right-radius: 10px;
}
.tableFixHead::-webkit-scrollbar-track {
  background: #E6E6E6; /* color of the tracking area */
  border-bottom-right-radius: 10px;
}
.tableFixHead::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}
.sticky_top {
  position:sticky;
  /* top: 0px !important; */
  z-index: 660 !important;
}
.theme_table {
  border-collapse: separate;
  border-spacing: 0;
}
@media screen and (max-width: 1200px) {
  .theme_table tr td:first-child,
  .theme_table tr td:nth-child(2) {
    padding-left: 6px;
  }
}