.title {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--pure-white);
}

.back_btn {
  min-width: 90px;
  padding: 5px;
  color: var(--cerulean);
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid var(--cerulean);
  background-color: var(--alice-blue);
}
