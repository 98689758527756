.table {
  text-align: left;
}

.table th {
  color: var(--dune);
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

.table tr th:first-child,
.table tr td:first-child {
  /* width: 168px !important; */
  vertical-align: middle !important;
}

.table tr th {
  padding: 16px !important;
}

.table tr th,
.table tr td {
  text-align: center;
  vertical-align: middle;
  width: 210px !important;
  /* padding-left: 16px;
  padding-right: 16px; */
}

.table tr td:first-child {
  width: 208px !important;
}
.last_child {
 min-height: 60px;
 height: 60px;
 max-height: 120px;
}

.table tr td {
  vertical-align: inherit !important;
  font-size: 10px;
}

.no_data {
  /* height: 32px; */
}
