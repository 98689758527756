.general_textbox {
    width: 100%;
    position: relative;
}
.input {
    width: 100%;
    padding: 12px 22px 12px 54px;
    /* outline: none; */
    border: 0.678715px solid #D6E4EC;;
    border-radius: 6px;
    background-color: #FBFBFB;
     /* margin-left: 0px!important; */
}
.input::placeholder {
    font-size: 14px;
    /* padding-left: 54px; */
    color: var(--light-grey-6);
}
.input:focus {
    border: 1px solid var(--cerulean);
    box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
    background-color: var(--pure-white);
    /* padding-left: 22px; */
}
.icon {
    max-width: 20px;
    position: absolute;
    left: 22px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.reset_icon {
    max-width: 34px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}