
.yes_btn {
    background-color: var(--cerulean);
    color: var(--pure-white);
    text-transform: uppercase;
    border-radius: 12px;
    border: 1px solid var(--cerulean);
    font-weight: bold;
    margin: 0px 0px;
    width: 100%;
}