.keywords_selection_accor_summary_detail {
  /* padding-bottom: 0 !important; */
}
.cmn_btn_large {
  width: 180px;
}
.keyword_selection_box {
  display: flex;
  flex-wrap: wrap;
}
.keyword_selection_box > div {
  margin-bottom: 24px;
}
.keyword_and_text {
  /* width: 6%; */
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.keyword_or_box_out {
  width: 44%;
}
.none {
  display: none !important;
}
.keyword_action {
  margin-bottom: 16px;
  padding: 11px 32px;
  border-radius: 6px;
}
.keyword_action:last-child {
  margin-bottom: 0;
}
.keyword_action_del {
  padding-left: 24px;
}
.keyword_action_current_del {
  background: #E9FAFF;
}
.keyword_action_current_cancel {
  background: #FFE9E9;
}
.keyword_action_button {
  width: 80px;
  display: inline-block;
}
.del_inst {
  width: 40px;
  background-color: #fff;
  padding: 1px 4px 0;
  font-size: 10px;
  border: 1px solid #979797;
  display: inline-block;
  border-radius: 4px;
  color: #00A5D9;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
}
.del_inst_large {
  width: 65px;
}
.keyword_action_title {
  display: inline;
  vertical-align: middle;
  position: relative;
}
.keyword_action_current_done .keyword_action_title:after {
  content: '';
  width: 3px;
  height: 100%;
  background: #007AFF;
  position: absolute;
  right: -8px;
  top: 0;
  border-radius: 4px;
}


@media screen and (min-width: 992px) {
  .px_lg_0 {
    padding: 0px !important;
  }
}
@media screen and (max-width: 1200px) {
  .keyword_selection_box > div {
    width: 100%;
  }
}
