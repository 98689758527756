.logout_btn {
    background: #FFFFFF;
    width: 150px;
    min-height: 45px;
    /* padding-left: 32px;
    padding-right: 32px; */
    box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
    border-radius: 10px;
    color: #333333;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.logout_btn img {
    margin-right: 8px;
    ;
}

@media screen and (max-width: 767px) {
    .logout_btn {
        display: flex;
        align-items: center;
    }
}