
.control {
  position: relative;
  margin: 0;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 52px;
  height: 36px;
}
.control__indicator {
  width: 52px;
  height: 36px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  font-size: 18px;
  color: #D9D9D9;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator,
.control input:checked ~ .control__indicator {
  border-color: #00a5d9;
  color: #00a5d9;
}