.general_textbox {
  width: 100%;
}

.input {
  width: 100%;
  padding: 10px 16px;
  outline: none;
  border: 1px solid var(--light-grey-3);
  border-left: 0px !important;
  background-color: inherit;
  margin-left: 0px !important;
  height: 46px;
  pointer-events: none;
  font-size:14px;
  color: var(--granite);
}

.input::placeholder {
  font-size: 14px;
}

.input:focus {
  border: 1px solid var(--cerulean);
  border-left: 1px !important;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: var(--pure-white);
}
.right_inner_addon input {
    width: 228px; /* 200px - 30px */
}
.right_inner_addon img {
    position: absolute;
    margin-left: -34px;
    cursor: pointer;
    top: 3rem;
    width: 26px;
    height: 26px;
}
@media screen and (max-width: 768px) {
  .input {
    padding: 12px 16px;
  }
}
