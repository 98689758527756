.select_box {
    width: 100%;
    padding: 8px 16px 8px 24px;
    position: relative;
    cursor: pointer;
    background: #FBFBFB;
    border: 0.678715px solid #D6E4EC;
    box-sizing: border-box;
    border-radius: 5px;
}

.select_box_radius {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.select_box ul {
    background: var(--pure-white);
    width: 100%;
    list-style: none;
    padding: 0;
    margin-top: 9px;
    left: 0;
    position: absolute;
    z-index: 1000;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 0.68px solid #D6E4EC;
}

.select_box ul.showbox {
    transition: all 0.2s ease-in-out;
}

.select_box ul.hidebox {
    visibility: hidden;
    opacity: 0;
}

.select_box ul.over_height {
    max-height: 300px;
    overflow-y: scroll;
}

.select_box ul>li {
    padding: 4px 8px;
    cursor: pointer;
    min-height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.select_box ul>li:last-child {
    /* border-top: 0.68px solid #D6E4EC; */
}

.select_box ul>li:hover,
.select_box ul>li:focus {
    background-color: #E6E6E6;
}

.arrows {
    width: 12px;
    /* margin-top: 5px; */
}
