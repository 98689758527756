
.cmn-btn {
    width: 90px;
    background-color: var(--cerulean);
    padding: 5px;
    color: var(--pure-white);
    text-transform: uppercase;
    border-radius: 3px;
}
.btn-white {
    background-color: var(--pure-white);
    color: var(--cerulean);
    border: 1px solid #979797;
}
.action{
    width: auto;
}
.action >img{
    width: 20px;
    cursor: pointer;
}
.text_underline {
    text-decoration: underline;
}
