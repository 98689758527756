.next_screnerio_button {
  background: #e98300;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
}
.criterial_box:last-child {
  margin-bottom: 0 !important;
}

.check_button,
.check_button_2 {
  width: 40px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px 8px;
  color: #e6e6e6;
}
.check_button {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px 10px;
  color: #e6e6e6;
}

.check_button_2 {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px 13px;
  color: #e6e6e6;
}

.get_list_content {
  margin-top: 3px;
  display: none;
  background: #fbfbfb;
}

.get_list_content_show {
  display: block;
}

.get_url_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  font-size: 14px;
  padding: 16px 18px 16px 24px;
}

.icon {
  width: 15px;
  height: 10px;
  cursor: pointer;
}

.check_cretria_box {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 0 !important;
}

.border_radious_4 {
  border-radius: 4px !important;
}
.border_radious_6 {
  border-radius: 6px !important;
}

.list_box {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.list_box_inner {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  /* padding: 16px; */
}
.list_box_inner:last-child {
  margin-bottom: 0 !important;
}

.Underscoring {
  display: flex;
  align-items: center;
  text-align: center;
  color: #00a5d9;
  background: rgba(0, 165, 217, 0.2);
  border: 1px solid #00a5d9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 28px;
  font-family: RobotoRegular;
  height: 38px;
}

.in_progress_button {
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  background: #00a5d9;
  border: 1px solid #00a5d9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 28px;
  font-family: RobotoRegular;
  height: 38px;
}

.active {
  display: block;
}

.inactive {
  display: none;
}

.selected {
  background: #ffffff;
  border: 1px solid #00a5d9;
  box-sizing: border-box;
  border-radius: 2px;
}

.selected > button {
  color: #00a5d9;
}

.button {
  font-weight: bold;
  width: 52px;
  min-height: 32px;
  height: 38px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 2px;
  color: var(--light-grey-5);
  border: 1px solid var(--light-grey-5);
  font-size: 20px;
}

.btn_not_checked {
  color: var(--light-grey-5);
  border: 1px solid var(--light-grey-5);
}

.btn_checked {
  color: var(--cerulean);
  border: 1px solid var(--cerulean);
}
.progess {
  font-weight: bold;
}
.waiting {
  color: var(--light-grey-6);
}
.dropdown_box {
  width: 52px !important;
  padding: 2px 4px 0px 8px !important;
  border-radius: 2px !important;
}
.dropdown_box ul {
  border-radius: 2px !important;
}
.dropdown_box ul {
  width: 104% !important;
  left: -1px !important;
  top: -1px !important;
}

.mate_btn {
  color: #00a5d9 !important;
  background-color: #00a5d933 !important;
  border: 1px solid #00a5d9 !important;
  box-shadow: none !important;
  box-sizing: border-box;
  padding: 8px 16px;
  height: 38px;
  line-height: 38px;
  border-radius: 8px !important;
  width: 100px;
  font-family: RobotoRegular;
}
.mate_btn:hover {
  box-shadow: none !important;
}
.mate_btn_primary {
  background-color: #00a5d9 !important;
  border: 1px solid #00a5d9 !important;
  box-shadow: none !important;
  box-sizing: border-box;
  padding: 8px 16px;
  height: 38px;
  line-height: 38px;
  border-radius: 8px !important;
  width: 100px;
  font-family: RobotoRegular;
}
.mate_btn_primary:hover {
  box-shadow: none !important;
}
.grey {
  background: var(--light-grey-6);
}
.required {
  background: #ffe9e9;
}
.failed_btn {
  font-size:30px;
  line-height:0px;
}
.height {
  height: 70px;
  display: flex;
}