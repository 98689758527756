.header {
  /* max-width: 140px !important; */
  display: flex;
  justify-content: space-between;
}
.header_1 {
  /* padding-top: 20px; */
}
.header_2 {
  /* padding-top: 10px; */
}
.active_text {
  color: var(--cerulean);
}

.grey {
  background: var(--light-grey-6);
  border: 1px solid var(--light-grey-6);
}
.grey_selected {
  background: #fbfbfb;
  border: 0.678715px solid #d6e4ec;
  color: #b2b2b2;
}
.grey_text {
  color: #b2b2b2;
}
@media screen and (max-width: 991.98px) {
  .header {
    display: block;
    margin-bottom: 8px !important;
  }
}
