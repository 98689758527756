.header {
    /* max-width: 140px !important; */
    display: flex;
    justify-content:space-between;
  }
  .header_1 {
    /* padding-top: 20px; */
  }
  .header_2 {
    /* padding-top: 10px; */
  }
  .active_text {
    color: var(--cerulean);
  }
  
  
  @media screen and (max-width: 991.98px) {
  .header {
      display: block;
      margin-bottom: 8px !important;
  }
  }