#login_page {
  min-height: 100%;
  height: 100%;
  background-color: var(--white-lilac);
  min-width: 100%;
  background: url(https://picturesquestion640.blob.core.windows.net/va2-roleplay/kosta-bratsos-lBPgzz3HGIo-unsplash.jpg?sp=r&st=2021-08-29T12:54:23Z&se=2027-08-03T20:54:23Z&sip=0.0.0.0-255.255.255.255&spr=https&sv=2020-08-04&sr=b&sig=hwDSWXjmlDiC2NBC613DE3VgFGhlLgSs5ydcuwHUhVM%3D) 
    no-repeat top center;
  flex-direction: column;
  background-size: cover;
  position: relative;
}

#login_page::before {
  content: '';
  background: rgba(0, 165, 217, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.login_page {
  min-height: 100vh;
  /* min-height: calc(100vh - 50px); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  flex-direction: column;
  background-size: cover;
  position: relative;
}
.center_container {
  width: 520px;
  padding: 40px 40px;  
  background: #ffffff;
  border-radius: 10px;
  z-index: 1; 
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  text-align: center;
}

.logo {
  width: 124px; 
}

.title {
  padding: 16px 0; 
  margin: 0;
  text-align: center;  
  line-height: 28px;
}

.link {
  color: rgba(0, 165, 217, 1);
    display: block;
    text-decoration: underline;
}
@media screen and (max-width: 575.98px) {
  .center_container {
    width: 90%;
    margin: 0 auto 0;
  }
}
