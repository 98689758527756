.bg_box {
    margin-bottom: 24px;
    background: #FFFFFF;
    border: 1px solid var(--light-grey-3);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 16px 16px 8px 16px;
    font-family: MeiryoRegular;
}
.bg_box:last-child {
    margin-bottom: 0;
}
.scenario_checkGroup  {
   margin-top: 25px;
}
.select_scenario p {
    margin:0  7px ;
}
.border:before {
    /* content: "・";
    vertical-align: middle; */
}
li.border {
    background: var(--light-grey-0);
    border: 1px solid var(--light-grey-3);
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 8px 8px 0;
    /* font-size:10px; */
    padding: 2px 8px;
    color: black;
}

.custom_list {
    list-style-type: none;
    padding-left: 28px;
    margin-bottom: 0;
}

.required {
    background: #FFE9E9 !important;
}

@media screen and (max-width: 767px) {
    scenario_checkGroup ul li {
        width: 50% !important;
    }
}   