.keyword_or_box {
    /* width: 94%; */
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 6px 6px 0px 0px;
}
.keyword_or_box_up {
    background: #E9FAFF;
    padding: 16px 24px;
    border-radius: 6px 6px 0px 0px;
}
.keyword_or_box_middle {
    padding: 24px 8px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    /* height: calc( 100% - 114px); */
    min-height: 300px;
}
.keyword_or_box_down {    
    background: #F2F2F2;
    padding: 8px 24px;    
}
.keyword_selection_check {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.keyword_selection_check li {
    min-width: 104px;
    margin: 0 8px 16px 8px;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
    border-radius: 2px;
}
.keyword_selection_check label span:last-child {
    font-size: 12px !important;
    text-align: center !important;
    margin:0 auto !important;
}
.keyword_selection_button {
    background-color: var(--cerulean);
    padding: 5px 8px;
    color: var(--pure-white);
    text-transform: uppercase;
    border-radius: 4px;
    border: 1px solid var(--cerulean);
    font-weight: bold;
}
.recruiter_select {
    padding: 8px;
    }