#recruiter-summary-detail .description-link {
    position: relative;
    margin-bottom: 0;
    display: block;
}
#recruiter-summary-detail .description-link:hover .description-text {
    display: block;
}
#recruiter-summary-detail .description-text {
    display: none;
}
#recruiter-summary-detail .passfailevil {
    width : 260px
}
/* .study-theme-table th,
.step-table th ,
.result-detail-table th {
    font-size: 16px;
} */
.cmn-description {
    position: absolute;
    background: #fff;
    border: 1px solid #D9D9D9;
    padding: 8px;
    /* width: 258px; */
    top: 29px;
    white-space: initial;
    /* right: 0; */
    z-index: 9;
}
.description-table {
    right: auto;
}
.description-table tr th,
.description-table tr td {
    padding: 4px;
    vertical-align: middle;
}
@media screen and (max-width: 1400px) {
.last-cmn-description .cmn-description {
    right: 0;
}
}
@media screen and (max-width: 1300px) {
    .cmn-description {
        right: 0;   
    }
    
}
/* .recruiter_name {
    min-width: 260px;
    width: 300px;
} */
