.keyword_action {
  margin-bottom: 16px;
  padding: 11px 32px;
  border-radius: 6px;
  display: flex;
}
.keyword_action:last-child {
  margin-bottom: 0;
}
.keyword_action_del {
  padding-left: 24px;
}
.keyword_action_current_del {
  background: #e9faff;
}
.keyword_action_current_cancel {
  background: #ffe9e9;
}
.keyword_action_button {
  width: 80px;
  display: inline-block;
}
.del_inst {
  width: 40px;
  background-color: #fff;
  padding: 1px 4px 0;
  font-size: 10px;
  border: 1px solid #979797;
  display: inline-block;
  border-radius: 4px;
  color: #00a5d9;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
}
.del_inst_large {
  width: 65px;
}
.keyword_action_title {
  display: inline;
  vertical-align: middle;
  position: relative;
}
.keyword_action_current_done .keyword_action_title:after {
  content: '';
  width: 3px;
  height: 100%;
  background: #007aff;
  position: absolute;
  right: -8px;
  top: 0;
  border-radius: 4px;
}
.keyword_textbox {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}
.keyword_textbox:focus {
  box-shadow: none !important;
}
.done_button {
  width: 60px;
  background: var(--cerulean);
  border-radius: 4px;
  color: var(--pure-white);
  font-size: 10px;
  font-weight: bold;
  padding: 5px;
}
.keyword_action_current_add {
  background: var(--alice-blue)
}