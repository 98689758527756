.tabs {
  padding: 0;
}
.tabs > ul {
  padding: 0;
}

.tabs_titles {
  list-style: none;
  padding: 0px;
  margin: 0;
  display: flex;
  color: var(--light-grey-6);
  text-align: center;
}

.tab_title {
  display: inline-block;
  padding: 16px;
  cursor: pointer;
  flex: 1;
  background: var(--pure-white);
  border-bottom: 2px solid rgba(217, 217, 217, 0.5);
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); */
  font-weight: bold;
}
li.tab_title:first-child {
  border-top-left-radius: 8px;
}
li.tab_title:last-child {
  border-top-right-radius: 8px;
}
.tab_title__active {
  border-color: var(--cerulean);
  background-color: var(--cerulean);
  color: var(--pure-white);
}


.tab_content {
  display: flex;
}
.header {
  /* max-width: 140px !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .tabs_titles,
  .tab_content {
    flex-direction: column;
  }
  .header {
    display: block;
    margin-bottom: 8px !important;
  }
}
